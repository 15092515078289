import {
  Button,
  ButtonTheme,
  NCDialog,
  NCInput,
} from "@wolfiesports/srm-component";
import moment from "moment";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
  cancelSubscription,
  getBills,
  getMyAccount,
} from "../../../services/subscription";
import "../UserSettings.scss";

export type SubscriptionBills = {
  currency: string;
  date: number;
  email: string;
  endDate: number;
  invoice: string | null;
  name: string;
  paymentMethod: string;
  price: string;
  startDate: number;
  state: string;
  subscriptionId: string;
  target: string;
  userId: string;
  _id: string;
};

type SubscriptionAccount = {
  active: boolean;
  coin: number;
  email: string;
  endDate: number;
  nextPayment: number;
  paymentMethod: string;
  paypalId: string;
  status: string;
  stripeId: string;
  subscriptionId: string;
  trial: boolean;
  userId: string;
  _id: string;
};

export const PremiumHistorySettings = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [voucherCode, setVoucherCode] = useState("");
  const [bills, setBills] = useState<Array<SubscriptionBills>>();
  const [currentSubscription, setCurrentSubscripiton] =
    useState<SubscriptionAccount>();
  const [isCancelSubscriptionLoading, setIsCancelSubscriptionLoading] =
    useState(false);

  const handleVoucherCodeChange = (value: string) => {
    setVoucherCode(value);
  };

  const handleSendVoucher = () => {
    console.log(voucherCode);
  };

  useEffect(() => {
    const fetchBills = async () => {
      const res = await getBills();
      setBills(res.data);
    };

    fetchBills();
  }, []);

  useEffect(() => {
    const fetchMyAccounts = async () => {
      const res = await getMyAccount();
      setCurrentSubscripiton(res.data);
    };

    fetchMyAccounts();
  }, []);

  const handleCancelSubscription = async () => {
    setIsCancelSubscriptionLoading(true);
    cancelSubscription(currentSubscription?._id ?? "").then(() => {
      handleCloseConfirmationModal();
      setIsCancelSubscriptionLoading(false);
    });
  };

  const handleRedirectToPremium = () => {
    navigate("/premium");
  };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const handleSetShowConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  return (
    <div className="account-settings-content">
      <div className="title">
        <h4>
          <FormattedMessage id={"menu.label.premium"} />
        </h4>
      </div>
      <div className="premium-settings-page-container">
        {currentSubscription && (
          <div className="current-subscription">
            <h5>
              <FormattedMessage id={"webapp.settings.premium.status"} />
            </h5>
            <FormattedMessage
              id={"webapp.settings.premium.your-current-status-is"}
              description={"Your current status is :"}
            />
            <br />
            {currentSubscription.active ? (
              <>
                Active until :{" "}
                {moment(currentSubscription.endDate).format("DD/MM/YYYY")}
                <br />
                <FormattedMessage
                  id={"webapp.settings.premium.your-subscription-will-renew-on"}
                  description={"Your subscription will renew on :"}
                />
                &nbsp;
                {moment(currentSubscription.nextPayment).format("DD/MM/YYYY")}
                <br />
                <FormattedMessage
                  id={"webapp.settings.premium.cancel"}
                  description={
                    "You can cancel it anytime by clicking this button :"
                  }
                />
                &nbsp;
                <Button
                  setClick={handleSetShowConfirmationModal}
                  label={
                    <FormattedMessage
                      id={"webapp.settings.premium.cancel-my-subscription"}
                    />
                  }
                  theme={ButtonTheme.RED}
                />
                <NCDialog
                  show={showConfirmationModal}
                  setShow={handleCloseConfirmationModal}
                >
                  <div>
                    <h5>
                      <FormattedMessage
                        id={"webapp.settings.premium.are-you-sure-cancel-sub"}
                        description={
                          "Are you sure you want to cancel your subscription ?"
                        }
                      />
                    </h5>
                    <div className="cancel-dialog-button-part">
                      <Button
                        setClick={handleCancelSubscription}
                        label={
                          <FormattedMessage
                            id="webapp.premium.yes-cancel-now"
                            description="Yes, cancel now"
                          />
                        }
                        theme={ButtonTheme.RED}
                        disabled={isCancelSubscriptionLoading}
                      />
                      <Button
                        setClick={handleCloseConfirmationModal}
                        label={
                          <FormattedMessage
                            id="webapp.premium.no-keep-active"
                            description={"No, keep it active"}
                          />
                        }
                        theme={ButtonTheme.CLASSIC}
                        disabled={isCancelSubscriptionLoading}
                      />
                    </div>
                  </div>
                </NCDialog>
              </>
            ) : moment(currentSubscription.endDate) < moment() ? (
              <>
                <FormattedMessage
                  id={"webapp.settings.premium.inactive-get-premium-now"}
                  description={"Inactive, get premium now !"}
                />
                <Button
                  setClick={handleRedirectToPremium}
                  theme={ButtonTheme.PREMIUM}
                  label={
                    <FormattedMessage
                      id="webapp.premium.go-premium-again"
                      description="Go premium again"
                    />
                  }
                />
              </>
            ) : (
              <>
                <div className="d-flex">
                  <FormattedMessage
                    id={"webapp.settings.premium-still-on-but-inactive"}
                    description={
                      "Your premium is still active but it will end on:"
                    }
                  />
                  <div className="ml-1">
                    {moment(currentSubscription.endDate).format("DD/MM/YYYY")}
                  </div>
                </div>
                <Button
                  setClick={handleRedirectToPremium}
                  theme={ButtonTheme.PREMIUM}
                  label={
                    <FormattedMessage
                      id="webapp.premium.go-premium-again"
                      description="Go premium again"
                    />
                  }
                />
              </>
            )}
          </div>
        )}
        <div className="add-voucher-form-container">
          <h5>
            <FormattedMessage id={"webapp.premium.add-a-voucher"} />
          </h5>
          <div className="form-container">
            <NCInput
              name={intl.formatMessage({ id: "webapp.premium.add-a-voucher" })}
              placeHolder={intl.formatMessage({ id: "webapp.premium.voucher" })}
              value={voucherCode}
              onChange={handleVoucherCodeChange}
            />
            <Button
              setClick={handleSendVoucher}
              label={
                <FormattedMessage id={"webapp.premium.send-voucher-button"} />
              }
            />
          </div>
        </div>
        <div className="history-table-container">
          <h5>
            <FormattedMessage id={"webapp.premium.payment-history"} />
          </h5>
          {bills && bills.length ? (
            <table className="premium-history-table">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id={"webapp.premium.table-column-date"} />
                  </th>
                  <th>
                    <FormattedMessage
                      id={"webapp.premium.table-column-start-date"}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id={"webapp.premium.table-column-end-date"}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id={"webapp.premium.table-column-description"}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id={"webapp.premium.table-column-payment-method"}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id={"webapp.premium.table-column-payment-status"}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id={"webapp.premium.table-column-total"}
                    />
                  </th>
                  <th>
                    <FormattedMessage id={"webapp.premium.invoice"} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {bills.map((entry, index) => (
                  <tr key={index}>
                    <td>{moment(entry.date).format("DD/MM/YYYY")}</td>
                    <td>{moment(entry.startDate).format("DD/MM/YYYY")}</td>
                    <td>{moment(entry.endDate).format("DD/MM/YYYY")}</td>
                    <td>{entry.name}</td>
                    <td>{entry.paymentMethod}</td>
                    <td>{entry.state}</td>
                    <td>
                      {entry.price}&nbsp;
                      {entry.currency}
                    </td>
                    <td>
                      {entry.invoice ? (
                        <Button
                          setClick={() => {
                            window.open(entry.invoice!, "_blank");
                          }}
                          label={
                            <FormattedMessage
                              id={"webapp.premium.invoice.download"}
                            />
                          }
                        />
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
        </div>
      </div>
    </div>
  );
};
