import { Button, ButtonTheme, ButtonType, NCTournamentDiscord } from "@wolfiesports/srm-component";
import "./Contact.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { DiscordInviteResponse, DiscordService } from "../../services/discord.service";
import { useEffect, useState } from "react";

export const ContactPage = () => {
  const intl = useIntl();
  const [discordInvite, setDiscordInvite] = useState<Array<DiscordInviteResponse>>([]);

  const getDiscordInvite = async () => {
    try {
      const invite = await DiscordService.getInvite("7Pbu2BMmny");
      const invites: Array<DiscordInviteResponse> = [invite];
      setDiscordInvite(invites);
    } catch (error) {
      console.error("Couldn't generate discord invite");
    }
  };

  useEffect(() => {
    getDiscordInvite();
  }, []);

  return (
    <div className="contact-page-container">
      <h3>
        <FormattedMessage id={"webapp.contact.contact-us"} />
      </h3>

      <div className="grid">
        <div className="email-block">
          <h3><FormattedMessage id={"webapp.contact.email-label"} /></h3>
          <p>
          <FormattedMessage id={"webapp.contact.email-block"} />
          </p>
          <div className="buttons">
          <Button
              theme={ButtonTheme.CLASSIC}
              type={ButtonType.SECONDARY}
              setClick={() => window.location.href = "mailto:contact@wolfiesports.com"}
              label={
                <FormattedMessage id={"webapp.contact.send-email"} />
              }
            />
          </div>
        </div>

        <div className="help-center">
          <h3><FormattedMessage id={"webapp.contact.helpcenter-title"} /></h3>
          <p>
          <FormattedMessage id={"webapp.contact.helpcenter-content"} />
          </p>
          <div className="buttons">
          <Button
              theme={ButtonTheme.TOURNAMENT}
              type={ButtonType.SECONDARY}
              label={
                "Coming soon"
              }
            />
          </div>
        </div>
      </div>

      <div className="discord-block">
        <h3><FormattedMessage id={"webapp.contact.discord-join"} /></h3>
        <NCTournamentDiscord
          message={intl.formatMessage({ id: 'team.list.discord' })}
          invites={discordInvite}
        />
      </div>
    </div >
  );
};
