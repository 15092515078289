import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button, ButtonType, NCInput } from "@wolfiesports/srm-component";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  PaymentIntentType,
  SubscriptionPlan,
} from "../../../models/SubscriptionModel";
import {
  postStripeIntent,
  putStripeInformation,
  stripePay,
} from "../../../services/subscription-stripe";

const stripeOptions = {
  hidePostalCode: true,
  style: {
    base: {
      color: "white",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

type PremiumModalStripeMethodProps = {
  selectedPlan: SubscriptionPlan;
  paymentSuccess: () => void;
  paymentFailed: (reason: string) => void;
  handlePrevStep: () => void;
};

export const PremiumModalStripeMethod = ({
  selectedPlan,
  paymentSuccess,
  paymentFailed,
  handlePrevStep,
}: PremiumModalStripeMethodProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const [clientSecret, setClientSecret] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [name, setName] = useState("");
  const [billingLineOne, setBillingLineOne] = useState("");
  const [billingLineTwo, setBillingLineTwo] = useState("");
  const [city, setCity] = useState("");
  const [postCode, setPostCode] = useState("");
  const [country, setCountry] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleIntent = async () => {
    if (selectedPlan.oneTimePayment) {
      const res = await postStripeIntent(PaymentIntentType.PREMIUM, {
        premiumPlanId: selectedPlan._id,
      });
      if (res) {
        setClientSecret(res.data.clientSecret);
      }
    }
  };

  useEffect(() => {
    handleIntent();
  }, []);

  const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setProcessing(true);
    const cardElement = elements?.getElement(CardElement);

    if (!selectedPlan.oneTimePayment) {
      if (stripe && cardElement) {
        try {
          const response = await stripe.createToken(cardElement);
          if (response.token) {
            const cardId = await putStripeInformation(
              {
                billing_details: {
                  name: name,
                  address: {
                    city: city,
                    country: country,
                    line1: billingLineOne,
                    line2: billingLineTwo,
                    postal_code: postCode,
                  },
                },
              },
              response.token.id
            );
            if (cardId) {
              try {
                await stripePay(selectedPlan._id, cardId.data.cardId);
                paymentSuccess();
              } catch (error) {
                paymentFailed(
                  "Failed to proceed payment: " + (error as Error).message
                );
              }
            } else {
              paymentFailed(
                "Failed to proceed payment: Error generating card id"
              );
            }
          }
        } catch (error) {
          paymentFailed(
            "Failed to proceed payment: " + (error as Error).message
          );
        }
      }
      setProcessing(false);
    }

    if (stripe && clientSecret && cardElement) {
      try {
        await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: (ev.target as any).name.value,
            },
          },
        });
        setProcessing(false);
        paymentSuccess();
      } catch (error) {
        paymentFailed((error as Error).message ?? "Failed to proceed payment");
        setProcessing(false);
      }
    }
  };

  const handleChangeName = (value: string) => {
    setName(value);
  };
  const handleChangeAdressLineOne = (value: string) => {
    setBillingLineOne(value);
  };

  const handleChangeAdressLineTwo = (value: string) => {
    setBillingLineTwo(value);
  };

  const handleChangeCity = (value: string) => {
    setCity(value);
  };

  const handleChangePostCode = (value: string) => {
    setPostCode(value);
  };

  const handleChangeCountry = (value: string) => {
    setCountry(value);
  };

  useEffect(() => {
    if (selectedPlan.oneTimePayment) {
      setIsButtonDisabled(processing || !stripe || !clientSecret);
    } else {
      setIsButtonDisabled(processing || !stripe);
    }
  }, [processing, clientSecret, stripe]);

  return (
    <div className="stripe-checkout-form">
      <div className="sr-payment-form">
        <div className="sr-form-row" />
        <form onSubmit={handleSubmit}>
          <h5>Inform your card details</h5>
          <div className="stripe-form-container">
            <NCInput
              value={name}
              label={<FormattedMessage id="webapp.premium.stripe.form.name" />}
              onChange={handleChangeName}
              autofocus
            />
            <div className="adress-form-container">
              <NCInput
                value={billingLineOne}
                label={
                  <FormattedMessage id="webapp.premium.stripe.form.adress-line-1" />
                }
                onChange={handleChangeAdressLineOne}
              />
              <NCInput
                value={billingLineTwo}
                label={
                  <FormattedMessage id="webapp.premium.stripe.form.adress-line-2-(optional)" />
                }
                onChange={handleChangeAdressLineTwo}
              />
            </div>
            <div className="adress-form-part-two-container">
              <NCInput
                value={city}
                label={
                  <FormattedMessage id="webapp.premium.stripe.form.city" />
                }
                onChange={handleChangeCity}
              />
              <NCInput
                value={postCode}
                label={
                  <FormattedMessage id="webapp.premium.stripe.form.post-code" />
                }
                onChange={handleChangePostCode}
              />
              <NCInput
                value={country}
                label={
                  <FormattedMessage id="webapp.premium.stripe.form.county" />
                }
                onChange={handleChangeCountry}
              />
            </div>
          </div>

          <div className="card-element-container">
            <CardElement
              className="sr-input sr-card-element"
              options={stripeOptions}
            />
          </div>

          <div className="button-part">
            <Button
              setClick={handlePrevStep}
              label={<FormattedMessage id="webapp.premium.go-back" />}
            />
            <Button
              type={ButtonType.PRIMARY}
              label={
                processing ? (
                  <FormattedMessage id="webapp.premium.stripe.form.processing" />
                ) : (
                  <FormattedMessage id="webapp.premium.stripe.form.pay" />
                )
              }
              disabled={isButtonDisabled}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
