import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Button, NCDialog } from "@wolfiesports/srm-component";
import moment from "moment";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { SubscriptionPlan } from "../../models/SubscriptionModel";
import { PublicUserModel } from "../../models/User";
import { getUserById } from "../../services/no-user";
import { getPlans } from "../../services/subscription";
import { PremiumModalMpesaMethod } from "./Steps/Mpesa";
import {
  PaymentMethod,
  PremiumModalPaymentMethod,
} from "./Steps/PaymentMethod";
import { PremiumModalPaypalMethod } from "./Steps/Paypal";
import { SelectionPlan } from "./Steps/SelectionPlan";
import { PremiumModalStripeMethod } from "./Steps/Stripe";
import "./styles.scss";

const stripePromise = loadStripe(
  "pk_test_51NveXSA3vxATVpovyRhesbl4Sn72cePEw8hyuJlOTY6VWRJLYUgNAu6mWSbHUi6ncXSnZFuwEdwp0UVcrjGJBhzP00MpyoT7xa"
);

type PremiumModalProps = {
  handleOpenModal: boolean;
  handleCloseModal: () => void;
  selectedProductId?: string;
};

export const PremiumModal = ({
  handleOpenModal,
  handleCloseModal,
  selectedProductId,
}: PremiumModalProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const steps = [0, 1, 2, 3];

  const [errorOrWaiting, setErrorOrWaiting] = useState<
    "error" | "waiting" | null
  >(null);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    PaymentMethod | undefined
  >();

  const user = JSON.parse(localStorage.getItem("user") ?? "null");
  const navigate = useNavigate();

  const [fetchedUser, setFetchedUser] = useState<PublicUserModel>();

  const handleSelectPaymentMethod = (method: PaymentMethod) => {
    setSelectedPaymentMethod(method);
    setCurrentStep(2);
  };

  const handleSelectPlan = (selectedPlan: SubscriptionPlan) => {
    setSelectedPlan(selectedPlan);
    setCurrentStep(1);
  };

  const showSuccess = async () => {
    setCurrentStep(3);
    setErrorOrWaiting(null);
    const user = JSON.parse(localStorage.getItem("user") ?? "");
    if (user && selectedPlan) {
      user.account.premiumUntil = moment(
        user.account.premiumUntil ?? new Date()
      )
        .add(
          parseInt(selectedPlan.duration),
          selectedPlan.interval as moment.unitOfTime.DurationConstructor
        )
        .format("YYYY-MM-DDTHH:mm:ssZ");
      localStorage.setItem("user", JSON.stringify(user));
    }
  };

  const showFailure = (reason: string) => {
    setCurrentStep(3);
    setErrorOrWaiting("error");
    setErrorMessage(reason);
  };

  const showWaiting = () => {
    setCurrentStep(3);
    setErrorOrWaiting("waiting");
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    if (handleOpenModal) {
      setCurrentStep(0);
      setSelectedPlan(undefined);
      setErrorMessage(null);
      setSelectedPaymentMethod(undefined);
      setErrorOrWaiting(null);
    }
  }, [handleOpenModal]);

  useEffect(() => {
    if (user !== null && !fetchedUser) {
      getUserById(user.identity.id).then((response) => {
        setFetchedUser(response);
      });
    }
  }, [user, fetchedUser]);

  const handleRedirectToHomePage = () => {
    navigate("/tournament/home");
    handleCloseModal();
  };

  const [plans, setPlans] = useState<SubscriptionPlan[]>([]);

  useEffect(() => {
    const fetchPlans = async () => {
      const res = await getPlans(selectedProductId ?? "");

      const parsedPlans = selectedProductId
        ? res.data.filter(
            (product: any) => product.product === selectedProductId
          )
        : res.data;

      const filteredPlans = parsedPlans.filter(
        (plan: SubscriptionPlan) =>
          plan.active &&
          fetchedUser?.country &&
          (plan.countries.includes(fetchedUser.country) ||
            plan.countries.includes(fetchedUser.country.toLocaleLowerCase()))
      );

      setPlans(filteredPlans);
    };

    if (selectedProductId) {
      fetchPlans();
    }
  }, [selectedProductId, fetchedUser?.country]);

  return (
    <NCDialog show={handleOpenModal} setShow={handleCloseModal}>
      <div className="premium-modal-container">
        {plans.length > 0 && (
          <div className="stepper-container">
            {steps.map((step) => (
              <div
                key={step}
                className={`step ${currentStep === step ? "active" : ""} ${
                  errorMessage && currentStep === step ? "error" : ""
                }`}
              />
            ))}
          </div>
        )}

        {currentStep === 0 && fetchedUser && (
          <SelectionPlan
            handleSelectPlan={handleSelectPlan}
            plans={plans}
            user={fetchedUser}
          />
        )}

        {currentStep === 1 && selectedPlan && (
          <PremiumModalPaymentMethod
            setSelectedPaymentMethod={(method: PaymentMethod) =>
              handleSelectPaymentMethod(method)
            }
            handlePrevStep={handlePrevStep}
            selectedPlan={selectedPlan}
          />
        )}

        {currentStep === 2 &&
          selectedPaymentMethod === "paypal" &&
          selectedPlan && (
            <PremiumModalPaypalMethod
              selectedPlan={selectedPlan}
              paymentSuccess={showSuccess}
              paymentFailed={showFailure}
              handlePrevStep={handlePrevStep}
            />
          )}

        {currentStep === 2 &&
          selectedPaymentMethod === "stripe" &&
          selectedPlan && (
            <Elements stripe={stripePromise}>
              <PremiumModalStripeMethod
                selectedPlan={selectedPlan}
                paymentSuccess={showSuccess}
                paymentFailed={showFailure}
                handlePrevStep={handlePrevStep}
              />
            </Elements>
          )}

        {currentStep === 2 &&
          selectedPaymentMethod === "mpesa" &&
          selectedPlan && (
            <PremiumModalMpesaMethod
              selectedPlan={selectedPlan}
              paymentSuccess={showSuccess}
              paymentFailed={showFailure}
              paymentWaiting={showWaiting}
              handlePrevStep={handlePrevStep}
            />
          )}

        {currentStep === 3 && (
          <div className="success-error-step-container">
            {errorOrWaiting === "error" ? (
              <div>
                <h5>
                  <FormattedMessage
                    id={"webapp.premium.error.payment-failure"}
                  />
                </h5>
                <p>{errorMessage}</p>
              </div>
            ) : errorOrWaiting === "waiting" ? (
              <div>
                <h5>
                  <FormattedMessage id={"webapp.premium.waiting.title"} />
                </h5>
                <p>
                  <FormattedMessage id={"webapp.premium.waiting.message"} />
                </p>
                <Button
                  setClick={handleCloseModal}
                  label={
                    <FormattedMessage id="webapp.premium.waiting.close-button" />
                  }
                  styleClass="mx-auto"
                />
              </div>
            ) : (
              <>
                <h5>
                  <FormattedMessage
                    id={"webapp.premium.success.payment-success"}
                  />
                </h5>
                <p>
                  <FormattedMessage
                    id={"webapp.premium.success.congratulations"}
                  />
                </p>
                <div>
                  <p>
                    <FormattedMessage
                      id={"webapp.premium.success.you-purchased-value-pack"}
                    />
                    {selectedPlan?.name}
                  </p>
                  <p>
                    <FormattedMessage
                      id={"webapp.premium.success.your-account-is-now-premium"}
                    />
                  </p>
                  <Button
                    setClick={handleRedirectToHomePage}
                    label={
                      <FormattedMessage id="webapp.premium.button.go-to-compete" />
                    }
                    styleClass="mx-auto"
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </NCDialog>
  );
};
