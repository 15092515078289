import axios, { AxiosError } from 'axios';

export interface DiscordGuild {
    id: string;
    name: string;
    icon: string;
}

export interface DiscordInviteResponse {
    code: string,
    guild: DiscordGuild
    approximate_member_count?: number
    /* To see the full object fields, visit https://discord.com/developers/docs/resources/invite */
}

export class DiscordService {
    private static baseUrl =
        `${String(process.env.REACT_APP_DISCORD_API)}/v${String(process.env.REACT_APP_DISCORD_API_VERSION)}`;

    static async getInvite(code: string): Promise<DiscordInviteResponse> {
        try {
            const res = await axios.get(`${DiscordService.baseUrl}/invites/${code}?with_counts=true`);
            return res.data;
        } catch (e) {
            throw (e as AxiosError).response;
        }
    }
}
