import { Button, ButtonTheme, IconType } from "@wolfiesports/srm-component";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { PremiumModal } from "../../components/PremiumModal/PremiumModal";
import { PremiumProduct } from "../../models/SubscriptionModel";
import { getAllProducts } from "../../services/subscription";
import "./Premium.scss";

const bgImage = require("../../assets/Premium/premium-bg.png");
const bgScrached = require("../../assets/Premium/scratch-texture.png");
const premiumImage = require("../../assets/Premium/premium.png");

const items: string[] = [
  "premium-presentation-item-1",
  "premium-presentation-item-2",
  "premium-presentation-item-3",
  "premium-presentation-item-4",
];

export const PremiumPage = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [products, setProducts] = useState<PremiumProduct[]>();
  const [selectedProductId, setSelectedProductId] = useState<string>();
  const user = JSON.parse(localStorage.getItem("user") ?? "null");
  const navigate = useNavigate();

  const fetchProducts = async () => {
    const res = await getAllProducts();
    setProducts(res);
  };
  useEffect(() => {
    fetchProducts();
  }, []);

  const handleOpenModal = (product: PremiumProduct) => () => {
    if (user === null) {
      navigate("/login");
    }
    setIsOpenModal(!isOpenModal);
    if (product) {
      setSelectedProductId(product._id);
    }
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  return (
    <>
      <div className="premium-page-container">
        <div
          style={{ backgroundImage: `url(${bgImage})` }}
          className="bg-image-container"
        />
        <div className="content-container">
          <div className="title-container">
            <h3>
              <FormattedMessage id="premium-presentation.unlock-the-power-of-an-all-in-one-platform" />
            </h3>
            <h4>
              <FormattedMessage id="premium-presentation.build-now-your-success-faster-than-ever" />
            </h4>
          </div>
          <div className="premium-presentation-container">
            {products &&
              products.map((product, index) => {
                return (
                  <div
                    className="premium-item"
                    style={{ backgroundImage: `url(${bgScrached})` }}
                    key={index}
                  >
                    <div
                      className="top-image-container"
                      style={{ backgroundImage: `url(${premiumImage})` }}
                    />
                    <div className="premium-item-price">
                      <h6>{product.name}</h6>
                    </div>
                    <div className="premium-item-list">
                      {items.map((item, index) => {
                        return (
                          <div className="premium-list-items" key={index}>
                            <div className="dot" />
                            <p>
                              <FormattedMessage id={item} />
                            </p>
                          </div>
                        );
                      })}
                    </div>
                    <Button
                      theme={ButtonTheme.PREMIUM}
                      icon={{ type: IconType.Premium, width: 20, height: 20 }}
                      setClick={handleOpenModal(products[index])}
                    >
                      <FormattedMessage id="premium-presentation.subscribe" />
                    </Button>
                  </div>
                );
              })}
          </div>
          {/* <PremiumComparePlansSection /> */}
        </div>
      </div>
      <PremiumModal
        handleOpenModal={isOpenModal}
        handleCloseModal={handleCloseModal}
        selectedProductId={selectedProductId}
      />
    </>
  );
};
