import { CognitoUser } from "amazon-cognito-identity-js";
import axios from "axios";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../config/varStorage";
import { UserModel } from "../models/User";
import { getCookie, setCookie } from "../utils/cookies";
import sessionApi from "../utils/session/sessionApi";
import { getPublicGames, getUserGamesNoUpdate } from "./no-games";
import { getUserById } from "./no-user";

export const getUserInfos = async (): Promise<CognitoUser | null> => {
  try {
    const response = await axiosAuthenticated.get(
      process.env.REACT_APP_BASE_API_URL + "/nu/me"
    );
    return response.data;
  } catch (error) {
    console.log(`getUserInfos : ${error}`);
    return null;
  }
};

export const getUserFavoriteGames = async (userId: string) => {
  try {
    const publicGamesRes = await getPublicGames();
    const userGamesRes = await getUserGamesNoUpdate(userId);

    const userGameRoutes = userGamesRes.list.map((game: any) => game.route);
    const filteredGames = publicGamesRes.list.filter((game: any) => {
      const fullRoute = game.route + "/" + game.id;
      const match = userGameRoutes.includes(fullRoute);
      return match;
    });

    const result = filteredGames.map((game: any) => ({
      id: game.id,
      name: game.title,
      slug: game.id,
    }));

    return result;
  } catch (error) {
    console.error("Failed to fetch games:", error);
    return [];  
  }
};

export const populateStorage = async (user: any) => {
  const favoriteGames = await getUserFavoriteGames(user.userId);
  const userSettings = await getUserById(user.userId);

  const userObject: UserModel = {
    account: {
      confirmation: "CONFIRMED", // ???
      createdAt: user.reg,
      isPremium: new Date(user.premium.until) >= new Date(),
      premiumUntil: user.premium.until,
      registrationOrigin: user.origin,
      subscriptionStatus: user.premium.status,
    },
    identity: {
      id: user.userId,
      birthday: user.birthday,
      code: user.code,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      nickname: user.name,
      phoneNumber: user.phone.number,
      userTag: user.nickname + user.code ? "#" + user.code : null,
      biography: user.biography,
      countryCode: user.country,
    },
    settings: {
      banner: userSettings.banner,
      avatar: userSettings.avatar,
      isOptin: userSettings.optIn,
      currency: userSettings.currency,
    },
    gameInfo: {
      favoriteGames: favoriteGames,
      favoritePlatforms: [],
      preferredGames: [],
    },
  };

  localStorage.setItem("user", JSON.stringify(userObject));

  return userObject;
};

export const updateLocalStorageObject = (
  key: string,
  path: string,
  value: any
) => {
  const item = localStorage.getItem(key);
  if (!item) return;

  let data: any = JSON.parse(item);
  const pathArray = path.split(".");

  let current = data;
  for (let i = 0; i < pathArray.length - 1; i++) {
    const pathSegment = pathArray[i];
    if (!(pathSegment in current)) return;
    current = current[pathSegment];
  }

  const property = pathArray[pathArray.length - 1];
  if (!(property in current)) return;
  current[property] = value;

  localStorage.setItem(key, JSON.stringify(data));
};

export const axiosAuthenticated = axios.create();

axiosAuthenticated.interceptors.request.use(
  async (config) => {
    let accessToken = localStorage.getItem("accessToken");

    if (isTokenExpired(accessToken)) {
      accessToken = await refreshAccessToken();
      localStorage.setItem("accessToken", accessToken);
    }

    config.headers["x-access-token"] = accessToken;
    config.headers["Authorization"] = accessToken;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const isTokenExpired = (token: string | null) => {
  if (!token) return true;

  const payloadBase64 = token.split(".")[1];
  const decodedJson = atob(payloadBase64);
  const decoded = JSON.parse(decodedJson);

  const currentTime = Date.now().valueOf() / 1000;
  const expTime = decoded.exp;

  return currentTime > expTime;
};

export const refreshAccessToken = async () => {
  const refreshToken = await getCookie(REFRESH_TOKEN);

  if (!refreshToken) {
    throw new Error("No refresh token available");
  }

  const currentUser = await sessionApi.retrieveSession(
    undefined,
    undefined,
    refreshToken.value
  );

  if (!(currentUser instanceof CognitoUser)) {
    throw new Error("Failed to retrieve current user");
  }

  await sessionApi.refreshSession(currentUser, refreshToken.value);

  const newAccessToken = currentUser
    .getSignInUserSession()
    ?.getAccessToken()
    .getJwtToken();

  if (!newAccessToken) {
    throw new Error("Failed to refresh access token");
  }

  // Optionally, update the cookie with the new access token
  const accessTokenExpiration = currentUser
    .getSignInUserSession()
    ?.getAccessToken()
    .getExpiration();
  if (accessTokenExpiration) {
    setCookie(ACCESS_TOKEN, newAccessToken, {
      Expires: new Date(accessTokenExpiration * 1000).toUTCString(),
    });
  }

  return newAccessToken;
};
